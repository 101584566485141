import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { ProgressChartDataItem } from '../../components/progress-chart/progress-chart.component';
import { HomePageService } from '../../services/home-page.service';
import { UserBudgetSummaryDO } from 'app/shared/types/user-budget-summary.interface';
import { ViewSectionName } from '../../../dashboard/dashboard.types';
import { UserDataService } from 'app/shared/services/user-data.service';

@Component({
  selector: 'campaign-spent-widget',
  styleUrls: ['./campaign-spent-widget.component.scss'],
  templateUrl: './campaign-spent-widget.component.html'
})
export class CampaignSpentWidgetComponent implements OnInit, OnDestroy {
  private readonly widgetStateManager = inject(WidgetStateService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly homePageService = inject(HomePageService);
  private readonly utilityService = inject(UtilityService);
  private readonly router = inject(Router);
  private readonly configuration = inject(Configuration);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly userDataService = inject(UserDataService);

  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private budgetSummary: UserBudgetSummaryDO = null;
  private MAX_VALUE_LIMIT = 999;
  private contextChanged = false;
  public editPermission = false;
  public currentBudget: Budget = null;
  public widgetState = WidgetState;
  public state = WidgetState.INITIAL;
  public cegFlagEnabled = false;
  public overlayText = 'Click to view all campaigns';

  public spendProgress;
  public plannedProgress;
  public allocatedProgress;
  public campaigns = [];
  public allocatedData: ProgressChartDataItem[] = [];
  public spendData: ProgressChartDataItem[] = [];

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.loadContextData();
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state: WidgetState) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private prepareChartData() {
    const {
      total_budget,
      total_campaigns_allocated_amount,
      total_campaigns_planned_expenses,
      total_campaigns_spent_expenses
    } = this.budgetSummary;
    this.contextChanged = false;
    this.spendProgress =
      HomePageService.normalizeDecimal(total_campaigns_spent_expenses / total_campaigns_allocated_amount, this.MAX_VALUE_LIMIT);
    this.plannedProgress =
      HomePageService.normalizeDecimal(total_campaigns_planned_expenses / total_campaigns_allocated_amount, this.MAX_VALUE_LIMIT);
    this.allocatedProgress =
      HomePageService.normalizeDecimal(total_campaigns_allocated_amount / total_budget, this.MAX_VALUE_LIMIT);
    this.allocatedData = [
      {
        name: 'series1',
        color: '#76CEFF',
        value: this.allocatedProgress
      }
    ];
    this.spendData = [
      {
        name: 'series1',
        color: '#7C4FFF',
        value: this.spendProgress
      },
      {
        name: 'series2',
        color: '#E82987',
        value: this.plannedProgress
      }
    ];

    if (this.cegFlagEnabled) {
      this.spendData[0].color = 'rgba(0, 99, 183, 1)';
    }

    setTimeout(() => {
      this.setState(WidgetState.READY);
    });
  }

  public loadContextData() {
    this.userDataService.editPermission$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        editPermission => this.editPermission = editPermission
      );

    const summary$ = this.homePageService.userBudgetSummary$
      .pipe(
        filter(event => event && event.budgetId === this.currentBudget?.id),
        filter(event => event.forceReload || this.contextChanged),
        tap(event => {
          this.budgetSummary = event?.data;
        })
      );

    const campaignsList$ = this.budgetDataService.lightCampaignList$
      .pipe(
        filter(data => data != null),
        tap(data => {
          this.campaigns =
            this.homePageService.filterObjectsBySegment(
              this.budgetDataService.segmentsSnapshot,
              this.budgetDataService.sharedCostRulesSnapshot,
              data
            );
        }),
        take(1)
      );

    this.homePageService.contextData$
      .pipe(
        filter(data => data != null),
        tap((data) => {
          this.currentBudget = data.budget;
          this.cegFlagEnabled = !!this.currentBudget?.new_campaigns_programs_structure;
          this.contextChanged = true;
          this.setState(WidgetState.LOADING);
        }),
        switchMap(() => combineLatest([ summary$, campaignsList$ ])),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => { this.prepareChartData(); },
        (err) => this.handleError(err)
      );
  }

  public overlayAction() {
    this.router.navigate([this.configuration.ROUTING_CONSTANTS.MANAGE_PAGE, ViewSectionName.campaigns]);
  }

  public openCampaignCreation() {
    this.appRoutingService.openCampaignCreation();
  }
}
