import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, zip } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Budget } from 'app/shared/types/budget.interface';
import { CompanyDO } from 'app/shared/types/company.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { COLORS } from 'app/shared/constants/colors.constants';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { MetricService } from 'app/shared/services/backend/metric.service';
import { Configuration } from 'app/app.constants';
import { parseCompositeObjectKey } from 'app/shared/utils/common.utils';
import WidgetLegendItem from '../../types/widget-legend-item.interface';
import WidgetLegendIcon from '../../types/widget-legend-icon.type';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { UpdateMetricsDataItem, UpdateMetricsObjectRecord } from '../../types/update-metrics-data-item.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { HomePageService } from '../../services/home-page.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { UpdateMetricsChangeEvent } from '../../types/update-metrics-change-event.interface';
import {
  UpdateMetricsCampaignRecordDO,
  UpdateMetricsSummaryDO
} from '../../types/update-metrics-summary-do.interface';
import { HomePageEventType } from '../../types/home-page-event.type';
import { HomePageEventService } from '../../services/home-page-event.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';

@Component({
  selector: 'update-metrics-widget',
  styleUrls: ['./update-metrics-widget.component.scss'],
  templateUrl: './update-metrics-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateMetricsWidgetComponent implements OnInit, OnDestroy {
  private readonly widgetStateManager = inject(WidgetStateService);
  private readonly utilityService = inject(UtilityService);
  private readonly homePageService = inject(HomePageService);
  private readonly homePageEventService = inject(HomePageEventService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly userDataService = inject(UserDataService);
  private readonly metricsUtilsService = inject(MetricsUtilsService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly metricService = inject(MetricService);
  private readonly configuration = inject(Configuration);
  private readonly cdRef = inject(ChangeDetectorRef);

  @Input() config: WidgetConfig;

  private readonly destroy$ = new Subject<void>();
  private currentBudget: Budget = null;
  private company: CompanyDO = null;
  private readonly OBJECT_TYPES = this.configuration.OBJECT_TYPES;
  private contextChanged = false;
  private updateMetricsSummary: UpdateMetricsSummaryDO;

  public currencySymbol = '';
  public metrics: MetricType[] = [];
  public state = WidgetState.INITIAL;
  public widgetState = WidgetState;
  public legendItems: WidgetLegendItem[] = [
    {
      icon: WidgetLegendIcon.Square,
      color: COLORS.CORPORATE_MAGENTA,
      label: 'My Current YTD'
    },
    {
      icon: WidgetLegendIcon.TriangleBottom,
      color: COLORS.CORPORATE_PURPLE,
      label: 'Current Target'
    },
    {
      icon: WidgetLegendIcon.Square,
      color: COLORS.NAVY_VIOLET,
      opacity: .2,
      label: 'Gap to Target'
    }
  ];
  public tableData: UpdateMetricsDataItem[] = [];
  public isReadOnly = true;
  public readonly objectMode = ObjectMode;
  private closedCampaignsId: number[] = [];
  private products: ProductDO[] = [];

  private static getObjectRecordKey(id: number, objectType: string) {
    return `${objectType}_${id}`;
  }

  constructor() {
    this.userDataService.editPermission$
      .pipe(takeUntil(this.destroy$))
      .subscribe(editPermission => this.isReadOnly = !editPermission);
  }

  ngOnInit(): void {
    this.closedCampaignsId = [];

    this.setState(WidgetState.LOADING);
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );

    const widgetContext = combineLatest([
      this.companyDataService.selectedCompanyDO$,
      this.homePageService.metricsForUpdateSummary$,
      this.budgetObjectDetailsManager.getMetricTypes(),
      this.budgetObjectDetailsManager.getProducts$(),
      this.homePageService.contextData$,
      this.budgetDataService.lightCampaignList$,
    ] as any).pipe(
      takeUntil(this.destroy$),
      filter((data: any[]) => !data.includes(null))
    );
    widgetContext.subscribe({
      next: (data: any) => {
        const [company, updateMetricsSummary, metrics, products, context, campaigns] = data;
        this.company = company;
        this.currencySymbol = company.currency_symbol;
        this.updateMetricsSummary = updateMetricsSummary.data;
        this.metrics = metrics;
        this.products = products;
        this.currentBudget = context.budget;
        this.contextChanged = true;
        campaigns.forEach(item => item.mode === this.objectMode.Closed ? this.closedCampaignsId.push(item.id) : '');
        this.prepareData();
      },
      error: (err) => this.handleError(err)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setState(state) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private prepareNestedCampaigns(objects: Record<string, UpdateMetricsCampaignRecordDO>): Record<string, UpdateMetricsObjectRecord> {
    const preparedObjects: Record<string, UpdateMetricsObjectRecord> = {};

    Object.entries(objects).forEach(([key, item]) => {
      const { name, total_current, total_target, current, third_party, metric_mapping_id } = item;
      const objectId = Number(key);
      const objectRecordKey = UpdateMetricsWidgetComponent.getObjectRecordKey(metric_mapping_id, this.OBJECT_TYPES.campaign);

      preparedObjects[objectRecordKey] = {
        name,
        objectId,
        objectType: this.OBJECT_TYPES.campaign,
        mappingId: metric_mapping_id,
        total: total_current,
        target: total_target,
        current,
        withIntegrations: third_party > 0,
        closed: this.closedCampaignsId.includes(objectId)
      } as UpdateMetricsObjectRecord;
    });

    return preparedObjects;
  }

  private prepareData() {
    const updateMetricsEntries = Object.entries(this.updateMetricsSummary);
    this.contextChanged = false;

    if (!updateMetricsEntries.length) {
      this.setState(WidgetState.EMPTY);
      return;
    }

    this.tableData = updateMetricsEntries.map(([key, entry]) => {
      const { objectID: metricID, objectName: metricName } = parseCompositeObjectKey(key);
      const { total_current, total_target, campaigns, current_total_target } = entry;
      const metric = this.metrics.find(mt => mt.id === Number(metricID));
      const nestedCampaigns = this.prepareNestedCampaigns(campaigns);
      const campaignIds = Object.values(nestedCampaigns).map(camp => camp.objectId);
      const productName = this.products.find(product => product.id === metric.productId)?.name;

      return {
        name: metricName,
        withCurrency: metric && metric.withCurrency,
        isDecimal: this.metricsUtilsService.isMetricTypeDecimal(metric),
        summary: {
          total: total_current,
          target: total_target,
          currentTarget: current_total_target
        },
        objects: {
          ...nestedCampaigns
        },
        campaignIds: campaignIds,
        productName
      } as UpdateMetricsDataItem;
    });
    this.setState(WidgetState.READY);
  }

  private applyRecordValueChange(item: UpdateMetricsDataItem, record: UpdateMetricsObjectRecord, changeInValue: number, value: number) {
    record.current = value;
    record.total += changeInValue;

    if (record.objectType === this.OBJECT_TYPES.program && record.parentMappingId) {
      const parentObjectKey = UpdateMetricsWidgetComponent.getObjectRecordKey(record.parentMappingId, this.OBJECT_TYPES.campaign);
      const parentObject = item.objects[parentObjectKey];
      if (parentObject) {
        parentObject.total += changeInValue;
      }
    }

    item.summary = {
      ...item.summary,
      total: item.summary.total + changeInValue
    };
  }

  private updateCurrentRecordValue(record: UpdateMetricsObjectRecord) {
    this.metricService.updateMetricMapping(record.mappingId, { actual_amount: record.current })
      .subscribe(
        () => {
          this.homePageEventService.emitEvent({
            type: HomePageEventType.UPDATE_METRICS_CHANGE_EVENT
          });
        },
        (err) => this.handleError(err)
      )
  }

  public handleCurrentValueChange($event: UpdateMetricsChangeEvent) {
    const { record, value, item } = $event;
    const changeInValue = value - record.current;

    if (changeInValue !== 0) {
      this.applyRecordValueChange(item, record, changeInValue, value);
      this.updateCurrentRecordValue(record);
    }
  }
}
