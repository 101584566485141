import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';

import { SharedModule } from 'app/shared/shared.module';
import { HomePageComponent } from './components/home-page/home-page.component';
import { WidgetCardComponent } from './components/widget-card/widget-card.component';
import { TasksWidgetComponent } from './widgets/tasks-widget/tasks-widget.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { WidgetSectionComponent } from './components/widget-section/widget-section.component';
import { OverdueExpensesWidgetComponent } from './widgets/overdue-expenses-widget/overdue-expenses-widget.component';
import { PriorityActionsComponent } from './components/priority-actions/priority-actions.component';
import { OtherActionsComponent } from './components/other-actions/other-actions.component';
import { PlanHighlightsComponent } from './components/plan-highlights/plan-highlights.component';
import { BudgetObjectDetailsModule } from 'app/budget-object-details/budget-object-details.module';
import { SpendProgressWidgetComponent } from './widgets/spend-progress-widget/spend-progress-widget.component';
import { BudgetSpentWidgetComponent } from './widgets/budget-spent-widget/budget-spent-widget.component';
import { CampaignSpentWidgetComponent } from './widgets/campaign-spent-widget/campaign-spent-widget.component';
import { ProgressChartComponent } from './components/progress-chart/progress-chart.component';
import { SimpleWidgetHeaderComponent } from './components/simple-widget-header/simple-widget-header.component';
import { SpendChartValueComponent } from './components/spend-chart-value/spend-chart-value.component';
import { SpendChartSummaryComponent } from './components/spend-chart-summary/spend-chart-summary.component';
import { SpendChartPlaceholderComponent } from './components/spend-chart-placeholder/spend-chart-placeholder.component';
import { CampaignsSummaryWidgetComponent } from './widgets/campaigns-summary-widget/campaigns-summary-widget.component';
import { CampaignSummaryCardComponent } from './components/campaign-summary-card/campaign-summary-card.component';
import { OpenExpensesWidgetComponent } from './widgets/open-expenses-widget/open-expenses-widget.component';
import { HistoryWidgetComponent } from './widgets/history-widget/history-widget.component';
import { UpdateMetricsWidgetComponent } from './widgets/update-metrics-widget/update-metrics-widget.component';
import { MetricsProgressWidgetComponent } from './widgets/metrics-progress-widget/metrics-progress-widget.component';
import { WidgetCardPlaceholderComponent } from './components/widget-card-placeholder/widget-card-placeholder.component';
import { OverdueExpensesLegendComponent } from './components/overdue-expenses-legend/overdue-expenses-legend.component';
import { OverdueExpensesTableComponent } from './components/overdue-expenses-table/overdue-expenses-table.component';
import { AmountControlComponent } from './components/amount-control/amount-control.component';
import { GroupToggleControlComponent } from './components/group-toggle-control/group-toggle-control.component';
import { BudgetMinusExpensesWidgetComponent } from './widgets/budget-minus-expenses-widget/budget-minus-expenses-widget.component';
import { BudgetMinusExpensesWidgetNewWorldComponent } from './widgets/budget-minus-expenses-widget-new-world/budget-minus-expenses-widget.component';
import { TimeframePickerComponent } from './components/timeframe-picker/timeframe-picker.component';
import { HistoryWidgetTableComponent } from './components/history-widget-table/history-widget-table.component';
import { MetricsProgressChartComponent } from './components/metrics-progress-chart/metrics-progress-chart.component';
import { WidgetChartLegendComponent } from './components/widget-chart-legend/widget-chart-legend.component';
import { UpdateMetricsTableComponent } from './components/update-metrics-table/update-metrics-table.component';
import { UpdateMetricsPanelComponent } from './components/update-metrics-panel/update-metrics-panel.component';
import { WidgetToggleButtonComponent } from './components/widget-toggle-button/widget-toggle-button.component';
import { MetricProgressBarComponent } from './components/metric-progress-bar/metric-progress-bar.component';
import { ObjectLinkComponent } from './components/object-link/object-link.component';
import { TotalTargetValuesComponent } from './components/total-target-values/total-target-values.component';
import { EstimatedBusinessValueWidgetComponent } from './widgets/estimated-business-value-widget/estimated-business-value-widget.component';
import { AssignCampaignSegmentsWidgetComponent } from './widgets/assign-segments-google-campaigns-widget/assign-campaign-segments-widget.component';

@NgModule({
  declarations: [
    HomePageComponent,
    WidgetCardComponent,
    TasksWidgetComponent,
    HomeHeaderComponent,
    WidgetSectionComponent,
    OverdueExpensesWidgetComponent,
    PriorityActionsComponent,
    OtherActionsComponent,
    PlanHighlightsComponent,
    SpendProgressWidgetComponent,
    BudgetSpentWidgetComponent,
    CampaignSpentWidgetComponent,
    ProgressChartComponent,
    SimpleWidgetHeaderComponent,
    SpendChartValueComponent,
    SpendChartSummaryComponent,
    SpendChartPlaceholderComponent,
    CampaignsSummaryWidgetComponent,
    CampaignSummaryCardComponent,
    OpenExpensesWidgetComponent,
    HistoryWidgetComponent,
    UpdateMetricsWidgetComponent,
    MetricsProgressWidgetComponent,
    WidgetCardPlaceholderComponent,
    OverdueExpensesLegendComponent,
    OverdueExpensesTableComponent,
    AmountControlComponent,
    GroupToggleControlComponent,
    BudgetMinusExpensesWidgetComponent,
    BudgetMinusExpensesWidgetNewWorldComponent,
    TimeframePickerComponent,
    HistoryWidgetTableComponent,
    MetricsProgressChartComponent,
    WidgetChartLegendComponent,
    UpdateMetricsTableComponent,
    UpdateMetricsPanelComponent,
    WidgetToggleButtonComponent,
    MetricProgressBarComponent,
    ObjectLinkComponent,
    TotalTargetValuesComponent,
    EstimatedBusinessValueWidgetComponent,
    AssignCampaignSegmentsWidgetComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    SharedModule,
    BudgetObjectDetailsModule,
    FontAwesomeModule,
    MatButtonModule,
    MatTooltipModule,
    FormsModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatExpansionModule
  ],
  exports: []
})
export class HomeModule {}
