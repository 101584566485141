import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { AppDataLoader } from 'app/app-data-loader.service';
import { Budget } from 'app/shared/types/budget.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { CompanyDO } from 'app/shared/types/company.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { HomePageService } from '../../services/home-page.service';
import { WidgetStateService } from '../../services/widget-state.service';
import { WidgetState } from '../../types/widget.interface';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { HomePageEventService } from '../../services/home-page-event.service';
import { HomePageEvent, HomePageEventType } from '../../types/home-page-event.type';
import { MetricMappingDetailsService } from 'app/budget-object-details/services/metric-mapping-details.service';
import { BudgetObjectChangeEvent } from 'app/budget-object-details/types/budget-object-change.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { PriorityActionsComponent } from '../priority-actions/priority-actions.component';
import { PlanHighlightsComponent } from '../plan-highlights/plan-highlights.component';

@Component({
  selector: 'home-page',
  styleUrls: ['./home-page.component.scss'],
  templateUrl: './home-page.component.html',
  providers: [ AppDataLoader ]
})
export class HomePageComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private timeframesList: BudgetTimeframe[] = [];
  private segmentsList: BudgetSegmentAccess[] = [];
  private sharedCostRulesList: SharedCostRule[] = [];
  public currentBudget: Budget = null;
  public company: CompanyDO;
  public showPriorityActions;
  public showPlanHighlights;
  public widgetConfig;
  public showHomePage = true;

  @ViewChild(PriorityActionsComponent) priorityChildComponent: PriorityActionsComponent;
  @ViewChild(PlanHighlightsComponent) planChildComponent: PlanHighlightsComponent;

  constructor(
    private readonly budgetDataService: BudgetDataService,
    private readonly appDataLoader: AppDataLoader,
    private readonly companyDataService: CompanyDataService,
    private readonly utilityService: UtilityService,
    private readonly configuration: Configuration,
    private readonly homePageService: HomePageService,
    private readonly homePageEventService: HomePageEventService,
    private readonly widgetStateService: WidgetStateService,
    private readonly budgetObjectDetailsManager: BudgetObjectDetailsManager,
    private readonly metricMappingDetailsService: MetricMappingDetailsService,
  ) {}

  ngOnInit(): void {
    this.showPriorityActions = true;
    this.showPlanHighlights = true;
    this.loadContextData();
    this.budgetDataService.companyBudgetList$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (!data.length) {
            this.homePageService.setNoBudgetsFlag();
          }
        }
      );

    this.budgetObjectDetailsManager.budgetObjectChanged$
      .pipe(
        filter(data => data.eventType !== BudgetObjectChangeEvent.AttachmentsChanged),
        takeUntil(this.destroy$)
      )
      .subscribe(() => { this.reloadPage(); });

    this.metricMappingDetailsService.metricMappingChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onMetricMappingChanged();
      });

    this.homePageEventService.eventBus$
      .pipe(
        filter(event => event != null),
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        this.handleHomePageEvent(event);
      });

    this.widgetStateService.stateChanged$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (data) => {
          //this.budgetCegStatusEnabled = this.budgetDataService.selectedBudgetSnapshot?.new_campaigns_programs_structure;
          const { values } = data;
          this.widgetConfig = values;
          //if (this.budgetCegStatusEnabled) {
          //  this.filterWidgets(values[WidgetType.OVERDUE_EXPENSES], WidgetType.OVERDUE_EXPENSES);
          //}
          let noWidgetsToShow;
          const notAvailableStates = [WidgetState.EMPTY, WidgetState.HIDDEN];
          this.priorityChildComponent?.widgets?.forEach((widget)=>{
            if (noWidgetsToShow || noWidgetsToShow === undefined){
              noWidgetsToShow = notAvailableStates.includes(this.widgetConfig[widget.type]);
            }
          });
          this.priorityChildComponent?.widgets ? this.showPriorityActions = !noWidgetsToShow : '';
          noWidgetsToShow = undefined;
          this.planChildComponent?.widgets?.forEach((widget)=>{
            if (noWidgetsToShow || noWidgetsToShow === undefined){
              noWidgetsToShow = notAvailableStates.includes(this.widgetConfig[widget.type]);
            }
          });
          this.planChildComponent?.widgets ? this.showPlanHighlights = !noWidgetsToShow : '';
        }
      });

    this.appDataLoader.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleHomePageEvent(event: HomePageEvent) {
    switch (event.type) {
      case HomePageEventType.NO_OVERDUE_EXPENSES_LEFT:
        this.reloadExpensesData(true);
        break;

      case HomePageEventType.UPDATE_METRICS_CHANGE_EVENT:
        this.loadMetricsProgress(true);
        break;
    }
  }

  private reloadExpensesData(forceReload = false) {
    if (!this.currentBudget || !this.company || !this.timeframesList) {
      return;
    }

    forkJoin([
      this.homePageService.loadExpenses({
        budgetId: this.currentBudget.id,
        companyId: this.company.id,
        allocationsIds: this.timeframesList.map(tf => tf.id)
      }, forceReload),
      this.homePageService.loadUserBudgetSummary(this.currentBudget.id, forceReload)
    ])
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      error: (err) => this.handleError(err),
    });
  }

  private loadEstimatedBusinessValue(forceReload = false) {
    if (!this.currentBudget) {
      return;
    }

    this.homePageService.loadEstimatedBusinessValue(this.currentBudget.id, forceReload)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
        error: (err) => this.handleError(err)
      });
  }

  private loadMetricsProgress(forceReload = false) {
    if (!this.currentBudget) {
      return;
    }

    this.homePageService.loadMetricsProgress(this.currentBudget.id, forceReload)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
        error: (err) => this.handleError(err)
      });
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private onBudgetSelected() {
    if (!this.currentBudget) {
      return;
    }

    this.homePageService.loadUserBudgetSummary(this.currentBudget.id)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
        error: (err) => this.handleError(err)
      });

    this.homePageService.loadMetricsForUpdate(this.currentBudget.id)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
        error: (err) => this.handleError(err)
      });

    this.loadMetricsProgress();
    this.loadEstimatedBusinessValue();
    this.showPriorityActions = true;
    this.showPlanHighlights = true;
    this.showHomePage = false;
    setTimeout(() => {
      this.showHomePage = true;
    }, 0);
  }

  private onMetricMappingChanged() {
    if (!this.currentBudget) {
      return;
    }

    this.homePageService.loadMetricsForUpdate(this.currentBudget.id, true)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
        error: (err) => this.handleError(err)
      });
    this.loadMetricsProgress(true);
    this.loadEstimatedBusinessValue(true);
  }

  private onContextDataLoaded() {
    if (!this.currentBudget || !this.company) {
      return;
    }

    this.budgetDataService.fetchShortCampaignList(
      this.company.id,
      this.currentBudget.id,
      this.configuration.campaignStatusNames.active,
      error => this.handleError(error)
    );

    this.budgetDataService.fetchShortProgramList(
      this.company.id,
      this.currentBudget.id,
      this.configuration.programStatusNames.active,
      error => this.handleError(error)
    );

    this.companyDataService.loadMetrics(this.company.id, this.handleError);
  }

  public loadContextData() {
    const segments$ = this.budgetDataService.segmentList$.pipe(
      tap(segments => this.segmentsList = segments)
    );

    const sharedCostRules$ = this.budgetDataService.sharedCostRuleList$.pipe(
      tap(sharedCostRules => this.sharedCostRulesList = sharedCostRules)
    );

    const budget$ = this.budgetDataService.selectedBudget$.pipe(
      filter(budget => budget != null),
      tap(budget => {
        this.currentBudget = budget;
        this.onBudgetSelected();
      })
    );

    const company$ =
      this.companyDataService.selectedCompanyDO$.pipe(
        filter(cmp => cmp != null),
        tap(company => {
          this.company = company;
          this.companyDataService.loadCompanyData(company.id, this.handleError);
        })
      );

    const tfList$ = this.budgetDataService.timeframeList$.pipe(
      tap(tfList => this.timeframesList = tfList)
    );

    combineLatest([ budget$, company$, tfList$, segments$, sharedCostRules$ ])
      .pipe(
        filter(data => {
          const [ budget, company, tfs, segments ] = data;
          const budgetMatchesCompany = company?.id && company?.id === budget?.company;
          const timeframesMatchBudget = budget?.id && tfs.every(tf => tf.budget === budget?.id);
          const segmentsMatchBudget = budget?.id && segments.every(seg => seg.budget === budget?.id);

          return budgetMatchesCompany && timeframesMatchBudget && segmentsMatchBudget;
        }),
        tap(([ budget, company, timeframes, segments, sharedCostRules ]) => {
          this.onContextDataLoaded();
          this.homePageService.setContextData({
            budget,
            company,
            timeframes,
            segments,
            sharedCostRules
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (err) => this.handleError(err)
      });
  }

  private reloadPage() {
    this.onContextDataLoaded();
    this.onBudgetSelected();
    this.homePageService.setContextData({
      budget: this.currentBudget,
      company: this.company,
      timeframes: this.timeframesList,
      segments: this.segmentsList,
      sharedCostRules: this.sharedCostRulesList,
    });
  }
}
