import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Validations } from 'app/app.validations';
import { withAPIV0ErrorHandling, getRequestOptions, handleBulkRequestError } from 'app/shared/utils/http-request.utils';
import { BudgetObjectCloneResponse } from 'app/shared/types/budget-object-clone-response.interface';
import { CampaignAllocationDO, CampaignDO, PlanCampaignDO, CampaignTypeDO } from 'app/shared/types/campaign.interface';
import { BulkOperationResponse } from 'app/shared/types/bulk-operation-response.interface';
import { DeepPartial } from 'app/shared/types/deep-partial.type';
import { API_V0_URL, API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { ApiV0Response } from '@shared/types/api-v0-response.interface';
import { AssumedAmountsDO, ObjectAmountsByTimeframes } from '@shared/types/object-amounts.interface';
import { ObjectStatusPayload } from '@manage-ceg/types/manage-ceg-page.types';
import { PfmV3Service } from '../base/pfm-v3.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private readonly actionUrl = inject(API_V0_URL);
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly v3_api_manager = inject(PfmV3Service);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly validation = inject(Validations);

  public apiPaths = {
    campaign: 'campaign/',
    campaignPlan: 'campaign/plan/',
    campaignType: 'campaign_type/',
    campaignAllocation: 'campaign_allocation/',
    campaignAllocations: 'campaign_allocations/',
    split: 'split/',
    thisMonthCampaign: 'campaign/current_month/',
    thisQuarterCampaign: 'campaign/current_quarter/',
    moveToBudget: 'move_to_other_budget/',
    multiDelete: 'multi_delete/',
    multiUpdate: 'multi_update/',
    amountsByTimeframes: 'amounts_by_timeframes/',
    campaignAmountsByTimeframes: 'assumed_campaign_amounts_with_currency/',
    updateAllocationByTimeframe: 'update_allocation_by_timeframe/',
    updateAmountStatusForCampaigns: 'update_amount_status_for_campaigns/',
    campaignCustomFieldFilters: 'campaign/campaign_filter/',
    listCampaigns: 'campaign/list/' // is the same as campaign_filter but optimized from backend
  };

  listCampaigns(data: object): Observable<CampaignDO[]> {
    let params = data;

    if(data['goal_type_ids'] && data['view_mode'] !== 'goals') {
      return of([]);
    }

    let customFiledFiltersPayload = { ...params };
    if(params['custom_fields'] && !Object.keys(params['custom_fields']).length) {
      delete customFiledFiltersPayload['custom_fields']
    }

    let campaignCustomFieldFilterPayload = { ...customFiledFiltersPayload };

    if('short' in params) {
      campaignCustomFieldFilterPayload['short'] = String(params['short']);
    }

    if('include_nested' in params) {
      campaignCustomFieldFilterPayload['include_nested'] = String(params['include_nested']);
    }

    if('include_pseudo_objects' in params) {
      campaignCustomFieldFilterPayload['include_pseudo_objects'] = String(params['include_pseudo_objects']);
    }

    return this.v3_api_manager.post<CampaignDO[]>(
      this.apiPaths.listCampaigns,
      campaignCustomFieldFilterPayload
    ).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCampaign(campaignId: number): Observable<CampaignDO> {
    return this.http.get<CampaignDO>(`${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/`);
  }

  getCampaigns(data: object): Observable<CampaignDO[]> {

    // API endpoint for Normal and Combined Filters to POST request
      let params = data;

      if(data['goal_type_ids'] && data['view_mode'] !== 'goals') {
        return of([]);
      }

      let customFiledFiltersPayload = { ...params };


      if(params['custom_fields'] && !Object.keys(params['custom_fields']).length) {
        delete customFiledFiltersPayload['custom_fields']
      }

      let campaignCustomFieldFilterPayload = { ...customFiledFiltersPayload };

      if('short' in params) {
        campaignCustomFieldFilterPayload['short'] = String(params['short']);
      }

      if('include_nested' in params) {
        campaignCustomFieldFilterPayload['include_nested'] = String(params['include_nested']);
      }

      if('include_pseudo_objects' in params) {
        campaignCustomFieldFilterPayload['include_pseudo_objects'] = String(params['include_pseudo_objects']);
      }

      return this.http.post<CampaignDO[]>(
        this.apiV2Url + this.apiPaths.campaignCustomFieldFilters,
        campaignCustomFieldFilterPayload
      ).pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPlanCampaigns(data: object): Observable<PlanCampaignDO[]> {
    return this.http.get<PlanCampaignDO[]>(`${this.apiV2Url}${this.apiPaths.campaignPlan}`, getRequestOptions(data));
  }

  getCurrentQuarterCampaigns(budget_id: number): Observable<CampaignDO[]> {
    return this.http.get<CampaignDO[]>(this.apiV2Url + this.apiPaths.thisQuarterCampaign, getRequestOptions({ budget_id }));
  }

  getCurrentMonthCampaigns(budget_id: number): Observable<CampaignDO[]> {
    return this.http.get<CampaignDO[]>(this.apiV2Url + this.apiPaths.thisMonthCampaign, getRequestOptions({ budget_id }));
  }

  updateCampaign(campaignId: number, data: Partial<CampaignDO>): Observable<CampaignDO> {
    return this.http.patch<CampaignDO>(
      this.apiV2Url + this.apiPaths.campaign + `${campaignId}/`,
      JSON.stringify(data)
    );
  }

  // It needed just to get calculated status_total
  // witch is possible only by GET request
  updateCampaignAndFetchResult(campaignId: number, data: Partial<CampaignDO>, customFieldsPayload?: Record<number,string[]>): Observable<CampaignDO> {
    const detailsDiff = Object.keys(data).length;
    let updateRequest$: Observable<any> = detailsDiff ? this.updateCampaign(campaignId, data) : of(true);

    // Adding custom fields payload to the request if there are any CF changes
    if(customFieldsPayload && Object.keys(customFieldsPayload).length) {
      updateRequest$ = this.updateCampaign(campaignId, { ...data, custom_fields: customFieldsPayload });
    }

    return updateRequest$.pipe(
      switchMap(() => this.getCampaign(campaignId))
    );
  }

  getCampaignTypes(companyId: number): Observable<CampaignTypeDO[]> {
    return this.http.get<CampaignTypeDO[]>(
      this.apiV2Url + this.apiPaths.campaignType,
      getRequestOptions({company: companyId})
    );
  }

  createCampaignType(campaignType: Partial<CampaignTypeDO>): Observable<CampaignTypeDO> {
    return this.http.post<CampaignTypeDO>(
      `${this.apiV2Url}${this.apiPaths.campaignType}`,
      JSON.stringify(campaignType)
    );
  }

  createCampaign(campaign: DeepPartial<CampaignDO>, customFieldsPayload?: Record<number,string[]>): Observable<CampaignDO> {
    let campaignCreationPayload = { ...campaign };
    if(customFieldsPayload && Object.keys(customFieldsPayload).length) {
      campaignCreationPayload = { ...campaignCreationPayload, custom_fields: customFieldsPayload };
    }

    return this.http.post<CampaignDO>(
      this.apiV2Url + this.apiPaths.campaign,
      campaignCreationPayload
    );
  }

  deleteCampaign(campaignId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/`);
  }

  cloneCampaign(campaignId: number): Observable<BudgetObjectCloneResponse> {
    return this.http.post<BudgetObjectCloneResponse>(`${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/clone/`, {});
  }

  addCampaignAllocation(data: Partial<CampaignAllocationDO>): Observable<CampaignAllocationDO> {
    return this.http.post<CampaignAllocationDO>(
      `${this.apiV2Url}${this.apiPaths.campaignAllocation}`,
      JSON.stringify(data)
    );
  }

  updateCampaignAllocation(campaignAllocId: number, data: Partial<CampaignAllocationDO>): Observable<CampaignAllocationDO> {
    return this.http.patch<CampaignAllocationDO>(
      `${this.apiV2Url}${this.apiPaths.campaignAllocation}${campaignAllocId}/`,
      JSON.stringify(data)
    );
  }

  validateUniqueName(companyId: number, budgetId: number, name: string): Observable<ApiV0Response<void>> {
    const requestData = { name, company_id: companyId, budget_id: budgetId };
    return withAPIV0ErrorHandling(
      this.http.get<ApiV0Response<void>>(this.actionUrl + 'campaigns/unique/', getRequestOptions(requestData))
    );
  }

  split(campaignId: number): Observable<void> {
    return this.http.post<void>(`${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/${this.apiPaths.split}`, null);
  }

  logCampaignView(campaignId: number): Observable<void> {
    return this.http.post<void>(`${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/log_view/`, {});
  }

  moveToBudget(campaignId: number, budgetId: number, companyId: number): Observable<void> {
    return this.http.patch<void>(
      `${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/${this.apiPaths.moveToBudget}`,
      {
        budget: budgetId,
        company: companyId
      }
    );
  }

  deleteMultiCampaigns(campaignIds: number[]): Observable<BulkOperationResponse<number>> {
    const { ValiditionMessages: MESSAGES } = this.validation;

    return this.http.delete<BulkOperationResponse<number>>(
      `${this.apiV2Url}${this.apiPaths.campaign}${this.apiPaths.multiDelete}`,
      { body: { ids: campaignIds } }
    ).pipe(
      catchError(err => handleBulkRequestError(err, campaignIds, MESSAGES.SOMETHING_WENT_WRONG))
    );
  }

  updateMultiCampaigns(data: Partial<CampaignDO>[]): Observable<BulkOperationResponse<Partial<CampaignDO>>> {
    const { ValiditionMessages: MESSAGES } = this.validation;

    return this.http.patch<BulkOperationResponse<Partial<CampaignDO>>>(
      `${this.apiV2Url}${this.apiPaths.campaign}${this.apiPaths.multiUpdate}`,
      data
    ).pipe(
      catchError(err => handleBulkRequestError(err, data.map(item => item.id), MESSAGES.SOMETHING_WENT_WRONG))
    );
  }

  getAmountsByTimeframes(budgetId: number, ids: number[], params?: object): Observable<ObjectAmountsByTimeframes> {
    
    // API endpoint for Normal and Combined Filters to POST request
    let payload = { ...params, budget: budgetId, ids: ids.join(',') };
    if(payload['custom_fields']) {
        delete payload['custom_fields'];
    }

    return this.http.post<ObjectAmountsByTimeframes>(
      `${this.apiV2Url}${this.apiPaths.campaign}${this.apiPaths.amountsByTimeframes}`,
      payload
    )
  }

  getCampaignAmountsByTimeframes(budgetId: number, campaignId: number, currencyCode: string): Observable<Record<string, AssumedAmountsDO>> {
    return this.http.get<Record<string, AssumedAmountsDO>>(
      `${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/${this.apiPaths.campaignAmountsByTimeframes}`,
      getRequestOptions({ currency_code: currencyCode, budget: budgetId })
    );
  }

  updateAmountByTimeframe(campaignId: number, data: Partial<CampaignAllocationDO>): Observable<CampaignAllocationDO> {
    return this.http.patch<CampaignAllocationDO>(
      `${this.apiV2Url}${this.apiPaths.campaign}${campaignId}/${this.apiPaths.updateAllocationByTimeframe}`,
      JSON.stringify(data)
    );
  }

  updateAmountStatusForCampaigns(payload: ObjectStatusPayload): Observable<BulkOperationResponse<number>> {
    const { ValiditionMessages: MESSAGES } = this.validation;

    return this.http.patch<BulkOperationResponse<number>>(
      `${this.apiV2Url}${this.apiPaths.campaign}${this.apiPaths.updateAmountStatusForCampaigns}`,
      payload
    ).pipe(
      catchError(err => handleBulkRequestError(err, payload.ids.map(itemId => itemId), MESSAGES.SOMETHING_WENT_WRONG))
    );
  }
}
