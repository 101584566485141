import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import {
  BudgetTimeframeBrief,
  ManageCegDataMode,
  ManageCegTableActionDataSource,
  ManageCegTableRow,
  ManageCegTableRowPerformance,
  ManageCegViewMode,
  ManageTableBudgetColumnName,
  ManageTableTotalValues,
  PerformanceColumnData,
  PresentationTimeframe
} from '@manage-ceg/types/manage-ceg-page.types';
import { Budget, BudgetTimeframesType } from '@shared/types/budget.interface';
import { ManageTableHelpers } from '../../manage-table/services/manage-table-helpers';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { ManageCEGTableDataBuilderInputs } from '@manage-ceg/types/manage-ceg-table-data-builder.types';
import { ManageCEGTableDataBuilder } from '@manage-ceg/services/manage-ceg-table-data-builder';
import { TableRowAmountsLoader, TableRowsDataLoader } from '@manage-ceg/types/manage-ceg-table-row-data.types';
import { ComposedTableRowsDataLoader } from '@manage-ceg/services/manage-ceg-table-row-data/composed-table-rows-data-loader';
import { SegmentAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/segment-amounts-loader';
import { UtilityService } from '@shared/services/utility.service';
import { SegmentGroupAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/segment-group-amounts-loader';
import { ExpenseGroupAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/expense-group-amounts-loader';
import { GoalAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/goal-amounts-loader';
import { CampaignAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/campaign-amounts-loader';
import { FilterManagementService, ParamsDef } from '../../header-navigation/components/filters/filter-services/filter-management.service';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { finalize, takeUntil } from 'rxjs/operators';
import { CampaignPerformanceAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/campaign-performance-amounts-loader';
import { GoalPerformanceAmountsLoader } from '@manage-ceg/services/manage-ceg-table-row-data/goal-performance-amounts-loader';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { BudgetPlanObjects } from '@shared/types/budget-plan-objects.type';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { MetricMappingDO } from '@shared/services/backend/metric.service';
import { CampaignPerformanceService } from '@manage-ceg/services/campaign-performance.service';

@Injectable()
export class ManageCegTableDataService implements OnDestroy {
  private expenseGroupAmountsLoader = inject(ExpenseGroupAmountsLoader);
  private campaignAmountsLoader = inject(CampaignAmountsLoader);
  private goalAmountsLoader = inject(GoalAmountsLoader);
  private segmentAmountsLoader = inject(SegmentAmountsLoader);
  private segmentGroupAmountsLoader = inject(SegmentGroupAmountsLoader);
  private campaignPerformanceAmountsLoader = inject(CampaignPerformanceAmountsLoader);
  private goalPerformanceAmountsLoader = inject(GoalPerformanceAmountsLoader);
  private utilityService = inject(UtilityService);
  private filterManager = inject(FilterManagementService);
  private managePageModeService = inject(ManageCegPageModeService);
  private campaignPerformanceService = inject(CampaignPerformanceService);

  public flatDataMap: Record<string, ManageCegTableRow> = {};
  public allTableRows: ManageCegTableRow[];
  private _budgetModeTableRowsDataLoader: TableRowsDataLoader = this.createBudgetTableRowsDataLoader();
  private _performanceModeTableRowsDataLoader: TableRowsDataLoader = this.createPerformanceTableRowsDataLoader();
  private _tableDataRowsChunkSize = 50;
  private _isFilteredMode = false;

  private _rows: ManageCegTableRow[];
  private _currentDataBuilder: ManageCEGTableDataBuilder;
  private dataInputs: ManageCEGTableDataBuilderInputs;
  public allRootRowsLoaded = true;

  private readonly _refreshTable$ = new Subject<void>();
  public readonly refreshTable$ = this._refreshTable$.asObservable();

  private readonly _isLoading$ = new BehaviorSubject(true);
  public readonly isLoading$ = this._isLoading$.asObservable();

  private readonly _grandTotalBudget$ = new BehaviorSubject<Record<PresentationTimeframe, ManageTableTotalValues>>(null);
  public readonly grandTotalBudget$ = this._grandTotalBudget$.asObservable();

  public readonly grandTotalForActiveTimeframes$ = new Subject<ManageTableTotalValues>();

  private readonly _grandTotalPerformance$ = new Subject<ManageCegTableRowPerformance>();
  public readonly grandTotalPerformance$ = this._grandTotalPerformance$.asObservable();

  private _timeframesAll: Record<BudgetTimeframesType, BudgetTimeframeBrief[]>;
  private readonly destroy$ = new Subject<void>();
  public hasHiddenHierarchy: boolean;
  private _performanceColumnData: PerformanceColumnData = {};

  private invalidateCurrentTableInputData$ = new Subject<void>();

  private static someItemHasHierarchyInfo(rows: ManageCegTableRow[]): boolean {
    const hasHierarchyInfo = rows.some(row => !row.isFilteredOut && !!row.hierarchyInfo);
    if (hasHierarchyInfo) {
      return true;
    } else {
      for (const row of rows) {
        if (row?.children.length) {
          const childHasHierarchyInfo = ManageCegTableDataService.someItemHasHierarchyInfo(row.children);
          if (childHasHierarchyInfo) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public static isSegmentlessObject(object: ManageCegTableRow): boolean {
    if (!object) {
      return false;
    }
    return !object?.segmentId && !object?.sharedCostRuleId;
  }

  public static containsSegmentlessObject(campaigns: LightCampaign[]): boolean {
    return campaigns != null && campaigns.some(campaign => !campaign.budgetSegmentId && !campaign.splitRuleId);
  }

  public get rows(): ManageCegTableRow[] {
    return this._rows;
  }

  public get performanceColumnData(): PerformanceColumnData {
    return this._performanceColumnData;
  }

  public get isFilteredMode(): boolean {
    return this._isFilteredMode;
  }

  public setFilteredMode(value: boolean) {
    this._isFilteredMode = value;
  }

  public get tableDataInputs(): ManageCEGTableDataBuilderInputs {
    return this.dataInputs;
  }

  public getBudgetModeAmountsLoaders(): Partial<Record<ManageTableRowType, TableRowAmountsLoader>> {
    return {
      [ManageTableRowType.SegmentGroup]: this.segmentGroupAmountsLoader,
      [ManageTableRowType.Segment]: this.segmentAmountsLoader,
      [ManageTableRowType.Goal]: this.goalAmountsLoader,
      [ManageTableRowType.Campaign]: this.campaignAmountsLoader,
      [ManageTableRowType.ExpenseGroup]: this.expenseGroupAmountsLoader
    };
  }

  public getPerformanceModeAmountsLoaders(): Partial<Record<ManageTableRowType, TableRowAmountsLoader>> {
    return {
      [ManageTableRowType.Goal]: this.goalPerformanceAmountsLoader,
      [ManageTableRowType.Campaign]: this.campaignPerformanceAmountsLoader
    };
  }

  public setLoading(state: boolean, delayMs = 0): void {
    setTimeout(() => {
      this._isLoading$.next(state);
    }, delayMs);
  }

  public getChildRowsDataLoader(dataMode: ManageCegDataMode, row?: ManageCegTableRow): TableRowsDataLoader {
    return row?.childRowsDataLoader || this.getDefaultChildRowsDataLoaderByMode(dataMode);
  }

  private getDefaultChildRowsDataLoaderByMode(dataMode: ManageCegDataMode): TableRowsDataLoader {
    if (dataMode === ManageCegDataMode.Performance) {
      return this._performanceModeTableRowsDataLoader;
    }

    return this._budgetModeTableRowsDataLoader;
  }

  public set timeframesAll(timeframes) {
    this._timeframesAll = timeframes;
  }

  public get timeframesAll(): Record<BudgetTimeframesType, BudgetTimeframeBrief[]> {
    return this._timeframesAll;
  }

  public setBudgetGrandTotals(totals: Record<PresentationTimeframe, ManageTableTotalValues>): void {
    this._grandTotalBudget$.next(totals);
  }

  public getBudgetGrandTotals(): Record<PresentationTimeframe, ManageTableTotalValues> {
    return this._grandTotalBudget$.getValue();
  }

  public setPerformanceGrandTotals(totals: ManageCegTableRowPerformance): void {
    this._grandTotalPerformance$.next(totals);
  }

  public getRecordById(id: string): ManageCegTableRow {
    return this.flatDataMap[id];
  }

  public getRelatedQuarterId(timeframeId: number): string | number {
    if (!this._timeframesAll.Month?.length) {
      return '';
    }

    const timeframeIndex = this._timeframesAll[BudgetTimeframesType.Month].findIndex(timeframeBrief =>
      timeframeBrief.id === timeframeId
    );
    return this._timeframesAll[BudgetTimeframesType.Quarter][Math.floor(timeframeIndex / 3)].id;
  }

  public triggerRefreshTable(): void {
    this._refreshTable$.next();
  }

  public getRecordByIdAndType(type: ManageTableRowType, id: number | string): ManageCegTableRow {
    const objectId = ManageTableHelpers.getRowObjectId(type, id);
    return this.getRecordById(objectId);
  }

  public getDataSourceValue(dataSource: ManageCegTableActionDataSource): number {
    const { record, timeframe } = dataSource;
    const valueObject = record.allocations[timeframe?.id];

    return valueObject?.[ManageTableBudgetColumnName.Budget]?.ownAmount || 0;
  }

  public getRecordAllocationsTotal(allocations: {
    company_budget_alloc: number,
    amount: number
  }[]): number {
    return allocations.reduce((sum, allocation) => sum + allocation.amount, 0);
  }

  public getRulesSegmentPercentage(scr: SharedCostRule, segmentId: number): number | null {
    const scrSegment = scr?.segments?.find(item => item.id === segmentId);
    if (!scrSegment) {
      return null;
    }

    return scrSegment.cost / 100;
  }

  public getSharedCostRule(ruleId: number): SharedCostRule {
    return this.dataInputs.sharedCostRules?.find(rule => rule.id === ruleId);
  }

  public updatePlanObjects(planObjects: BudgetPlanObjects): void {
    if (this.dataInputs) {
      this.dataInputs.planObjects = planObjects;
    }
  }

  public invalidateCurrentTableInputData(): void {
    this.invalidateCurrentTableInputData$.next();
  }

  public initTable(dataInputs: ManageCEGTableDataBuilderInputs): void {
    dataInputs = { ...dataInputs, filteredSegments: this.filterManager.currentFilterSetValue.segments as number[] }
    this.invalidateCurrentTableInputData();
    this.changeDataModeSwitchVisibility(dataInputs);
    this._rows = [];
    this._currentDataBuilder = new ManageCEGTableDataBuilder();
    const builderResult = this._currentDataBuilder.buildHierarchyData(dataInputs);
    this.allRootRowsLoaded = true;
    this.flatDataMap = builderResult.flatDataMap;
    this.allTableRows = builderResult.data;
    this.segmentGroupAmountsLoader.setSegments(dataInputs.segments);
    this.dataInputs = dataInputs;
    this.resetPerformanceColumnData();
    this.loadChunkOfRowsData(null);
    this.hasHiddenHierarchy = ManageCegTableDataService.someItemHasHierarchyInfo(this.allTableRows);
  }

  public updateTable(): void {
    // this.dataInputs should be already updated here!
    this.changeDataModeSwitchVisibility(this.dataInputs);
    this._currentDataBuilder = new ManageCEGTableDataBuilder();
    const builderResult = this._currentDataBuilder.buildHierarchyData(this.dataInputs);
    this.allRootRowsLoaded = true;
    const prevFlatDataMap = this.flatDataMap;
    this.flatDataMap = builderResult.flatDataMap;
    this.allTableRows = builderResult.data;
    this.hasHiddenHierarchy = ManageCegTableDataService.someItemHasHierarchyInfo(this.allTableRows);
    this._rows = [];

    this.copyNumbersFromPreviousRows(prevFlatDataMap, this.allTableRows);
  }

  public refreshAllRootRowsLoaded(): void {
    this.allRootRowsLoaded = this.areAllChildRowsLoaded(this.allTableRows, this._rows);
  }

  public objectExists(objType: ManageTableRowType, objId: number | string): boolean {
    return  this._currentDataBuilder?.objectExists(objType, objId);
  }

  public loadChunkOfRowsData(item: ManageCegTableRow): void {
    this.setLoading(true);
    const rowsContainer = item ? item.children : this.allTableRows;
    this.getChildRowsDataLoader(this.dataInputs.dataMode).loadNextChunk(
      this.dataInputs.budget.id,
      rowsContainer,
      this._timeframesAll,
      this._tableDataRowsChunkSize,
      this.getParamsFromFilters(this.dataInputs.viewMode, this.dataInputs.campaigns, this.dataInputs.segments, item),
    ).pipe(
      takeUntil(
        merge(this.invalidateCurrentTableInputData$, this.destroy$)
      ),
      finalize(() => {
        this.setLoading(false, 400);
      })
    ).subscribe({
      next: result => this.applyLoadedTableRowsData(result, item),
      error: e => {
        console.error(e);
        this.utilityService.handleError({ message: 'Failed to load row data'})
      }
    });
  }

  private applyLoadedTableRowsData(updatedRows: ManageCegTableRow[], containerItem: ManageCegTableRow): void {
    this.writeLoadedChildrenToFilteredOutRows(updatedRows);
    if (containerItem) {
      containerItem.loadedChildren.push(...updatedRows);
      containerItem.allChildrenLoaded = this.areAllChildRowsLoaded(containerItem.children, containerItem.loadedChildren);
    } else {
      this._rows.push(...updatedRows);
      this.refreshAllRootRowsLoaded();
    }
    this.triggerRefreshTable();
    this.updatePerformanceColumnData(updatedRows);
  }

  private writeLoadedChildrenToFilteredOutRows(rows: ManageCegTableRow[]) {
    rows
      .filter(row => !!row.isFilteredOut)
      .forEach(row => {
        this.refreshRowChildrenLoadingState(row);
        this.writeLoadedChildrenToFilteredOutRows(row.loadedChildren);
      });
  }

  private areAllChildRowsLoaded(allItems: ManageCegTableRow[], loadedItems: ManageCegTableRow[]): boolean {
    if (!allItems.length) {
      return true;
    }

    if (!loadedItems.length) {
      return false;
    }

    const getLastItemToDisplay = (items: ManageCegTableRow[], index: number = (items.length - 1)) => {
      const item = items[index];
      if (item.processed || !item.isFilteredOut || ComposedTableRowsDataLoader.hasChildToDisplay(item.children)) {
        return item;
      } else {
        return getLastItemToDisplay(items, index - 1);
      }
    }
    const lastItemToDisplay = getLastItemToDisplay(allItems);
    const lastLoadedItem = getLastItemToDisplay(loadedItems);
    return lastItemToDisplay.id === lastLoadedItem.id;
  }

  public refreshRowChildrenLoadingState(row: ManageCegTableRow): void {
    row.loadedChildren = row.children.filter(childRow => childRow.processed);
    row.allChildrenLoaded = this.areAllChildRowsLoaded(row.children, row.loadedChildren);
  }

  updateRootRow(rootRow: ManageCegTableRow): void {
    if (!this._rows.find(row => row.id === rootRow.id)) {
      const newRootRowIndex = this.allTableRows.findIndex(row => row.id === rootRow.id);
      if (newRootRowIndex !== -1) {
        this._rows.splice(newRootRowIndex, 0, rootRow);
      }
    }
    this.refreshAllRootRowsLoaded();
  }

  public getParamsFromFilters(
    viewMode: ManageCegViewMode,
    campaigns: LightCampaign[],
    allSegments: BudgetSegmentAccess[],
    parentRow?: ManageCegTableRow
  ): object {
    const isViewBySegment = viewMode === ManageCegViewMode.Segments;

    const getSegmentIdParamValue = () => {
      if (isViewBySegment && parentRow && parentRow.type !== ManageTableRowType.SegmentGroup) {
        return parentRow.type === ManageTableRowType.Segment ?
          parentRow.objectId :
          parentRow.segmentId;
      }
      return null;
    };

    const requestData = {
      'filtering_behaviour': 'children_agnostic', // For new world only
      'view_mode': viewMode,
    };
    const requestParamsDef: ParamsDef = {
      'company_budget_allocation_ids': { filterName: FilterName.Timeframes },
      'goal_ids': { filterName: FilterName.Goals },
      'tag_ids': { filterName: FilterName.Tags },
      'owner_ids': { filterName: FilterName.Owners },
      'campaign_ids': {
        filterName: FilterName.Campaigns,
        defaultValue: () => this.filterManager.getFilterCampaignIds(campaigns)
      },
      'object_type_ids': { filterName: FilterName.CampaignTypes },
      'program_ids': { filterName: FilterName.ExpenseBuckets },
      'expense_type_ids': { filterName: FilterName.ExpenseTypes },
      'gl_code_ids': { filterName: FilterName.GlCodes },
      'vendor_ids': { filterName: FilterName.Vendors },
      'split_rule_ids': { filterName: FilterName.SharedCostRules },
      'source': { filterName: FilterName.ExpenseSource },
      'metric_ids': { filterName: FilterName.Metrics },
      'company_budget_segment1_ids': {
        filterName: isViewBySegment ? null : FilterName.Segments,
        defaultValue: () => isViewBySegment ? null : this.filterManager.getDefaultSegments(allSegments)
      },
      'company_budget_segment1': { defaultValue: getSegmentIdParamValue },
      'po_numbers': { filterName: FilterName.PONumber },
      'amount_status': { filterName: FilterName.CEGStatus },
      'goal_type_ids': { filterName: FilterName.GoalTypes },
    };
    this.filterManager.setParamsFromFilters(requestData, requestParamsDef, true);
    return requestData;
  }

  private createBudgetTableRowsDataLoader(): TableRowsDataLoader {
    return new ComposedTableRowsDataLoader()
      .setRowDataLoader(ManageTableRowType.SegmentGroup, this.segmentGroupAmountsLoader)
      .setRowDataLoader(ManageTableRowType.Segment, this.segmentAmountsLoader)
      .setRowDataLoader(ManageTableRowType.Goal, this.goalAmountsLoader)
      .setRowDataLoader(ManageTableRowType.Campaign, this.campaignAmountsLoader)
      .setRowDataLoader(ManageTableRowType.ExpenseGroup, this.expenseGroupAmountsLoader);
  }

  private createPerformanceTableRowsDataLoader(): TableRowsDataLoader {
    return new ComposedTableRowsDataLoader()
      .setRowDataLoader(ManageTableRowType.Goal, this.goalPerformanceAmountsLoader)
      .setRowDataLoader(ManageTableRowType.Campaign, this.campaignPerformanceAmountsLoader);
  }

  private copyNumbersFromPreviousRows(
    prevFlatDataMap: Record<string, ManageCegTableRow>,
    newAllRows: ManageCegTableRow[],
    newContainer?: ManageCegTableRow
  ): void {
    for (const newRow of newAllRows) {
        const prevRow = prevFlatDataMap[newRow.id];

        if (prevRow?.processed) {
          newRow.performanceData = prevRow.performanceData;
          newRow.allocations = prevRow.allocations;
          newRow.presentationAllocations = prevRow.presentationAllocations;
          newRow.processed = true;
          if (!newContainer) {
            this._rows.push(newRow);
          }
        }

        if (newRow.children?.length) {
          this.copyNumbersFromPreviousRows(prevFlatDataMap, newRow.children, newRow);
        }
    }

    if (newContainer) {
      this.refreshRowChildrenLoadingState(newContainer);
    } else {
      this.refreshAllRootRowsLoaded();
    }
  }

  private updatePerformanceColumnData(updatedRows: ManageCegTableRow[], override = false): void {
    const campaignRows = updatedRows.filter(row => row.type === ManageTableRowType.Campaign);
    const targetCampaigns =
      campaignRows.reduce(
        (campaignsWithKeyMetric, campaignRow) => {
          const targetCampaign = this.dataInputs.campaigns.find(campaign => campaign.id === campaignRow.itemId);
          if (targetCampaign?.keyMetric) {
            campaignsWithKeyMetric.push(targetCampaign);
          }
          return campaignsWithKeyMetric;
        },
        []
      );

    this.updatePerformanceColumnDataForCampaigns(targetCampaigns);
  }

  public updatePerformanceColumnDataForCampaigns(campaignsWithKeyMetrics: LightCampaign[], override = false): void {
    if (campaignsWithKeyMetrics.length) {
      const budget = this.dataInputs.budget;
      this.campaignPerformanceService
        .loadCampaignPerformanceData$(campaignsWithKeyMetrics, budget.company)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          ([metricMappings, metricCampaigns]) => {
            this.changeDataModeSwitchVisibility(this.dataInputs);
            this.setPerformanceColumnData(metricMappings, metricCampaigns, budget, override);
            this.triggerRefreshTable();
          }
        );
    }
  }

  private setPerformanceColumnData(
    metricMappings: MetricMappingDO[],
    metricCampaigns: LightCampaign[],
    budget: Budget,
    overwrite = true
  ): void {
    const performanceData = this.campaignPerformanceService.getCampaignPerformanceColumnData(metricMappings, metricCampaigns, budget);
    this._performanceColumnData = {
      ...(overwrite ? {} : this._performanceColumnData),
      ...performanceData
    };
  }

  private resetPerformanceColumnData(): void {
    this._performanceColumnData = {};
  }

  private changeDataModeSwitchVisibility(dataInputs: ManageCEGTableDataBuilderInputs): void {
    const keyMetricId = dataInputs.campaigns.filter(campaign => campaign.keyMetric)[0]?.keyMetric || null;
    this.managePageModeService.showDataModeSwitch = !!keyMetricId;
  }

  removePerformanceColumnDataForCampaign(campaignId: number): void {
    this.changeDataModeSwitchVisibility(this.dataInputs);
    if (campaignId in this._performanceColumnData) {
      delete this._performanceColumnData[campaignId];
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
