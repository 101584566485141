import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PfmV2Service } from '../../../shared/services/base/pfm-v2.service';
import { BudgetAndSpentDataDO } from './budget-minus-expenses-widget.interface';

@Injectable()
export class BudgetMinusExpenseWidgetService {
  private v2_api_manager = inject(PfmV2Service);

  private endpoints = {
    budget_and_spent_data: (id: number) => `budget/${id}/budget_and_spent_data/`
  };

  public getBudgetMinusExpenses(budget_id: number, company_id: number) : Observable<BudgetAndSpentDataDO> {
    const preparedEndpoint = this.endpoints.budget_and_spent_data(budget_id);
    return this.v2_api_manager.get<BudgetAndSpentDataDO>(
      preparedEndpoint,
      {
        "company":company_id
      }
    );
  }
}
