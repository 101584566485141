<widget-card [isLoading]="state === widgetState.LOADING"
  [overlayText]="overlayText"
  [overlayAction]="overlayAction.bind(this)"
>
  <div class="budget-spent" [class.is-not-ready]="state !== widgetState.READY">
    <simple-widget-header>
      Fiscal year<br>
      {{ currentBudget?.budget_to | date: 'yyyy' }}
    </simple-widget-header>
    <div class="content">
      <div class="charts">
        <ng-container *ngIf="zeroExpense">
          <progress-chart
            radius="80"
            class="year-progress"
            [data]="yearChartData"
          ></progress-chart>
          <spend-chart-placeholder>
            No<br>
            Expenses<br>
            yet
          </spend-chart-placeholder>
        </ng-container>
        <ng-container *ngIf="!zeroExpense">
          <progress-chart
            radius="80"
            class="year-progress"
            [data]="yearChartData"
          >
          </progress-chart>
          <progress-chart
            radius="69"
            class="budget-progress"
            [data]="budgetChartData"
          >
          </progress-chart>
          <spend-chart-value
            [value]="budgetProgress"
            [cegFlagEnabled]="cegFlagEnabled"
          >
            <ng-container slot="value">Budget spent</ng-container>
            <ng-container *ngIf="!cegFlagEnabled" slot="remark">+ {{ plannedProgress | number: '1.0-0' }}% Planned expenses</ng-container>
          </spend-chart-value>
        </ng-container>
      </div>
      <spend-chart-summary>
        {{ yearProgress }}% of the year<br>
        has gone by
      </spend-chart-summary>
    </div>
  </div>
</widget-card>
